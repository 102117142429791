<template>
	<no-auth>
		<div class="friend-welcome-message">
			<!-- <div class="tips-box">
				<div class="tit">提示：</div>
				<div class="cont">
					<div>1.欢迎语将在客户加为好友后20秒内下发</div>
					<div>2.如果在企业微信后台→客户联系→加客户→欢迎语中已经配置了欢迎语，此时企业微信仅会发送企业微信后台配置的欢迎语</div>
					<div>3.好友欢迎语由企业统一配置，一个员工如果被设置了多个欢迎语，将会使用最新添加的欢迎语</div>
				</div>
			</div>
			<el-card style="margin-top: 15px;">
				<div slot="header" class="clearfix">
					<span>可使用员工配置</span>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="可使用员工" prop="employeeList">
						<div class="employee-list">
							<div class="employee-item" v-for="(item, index) in ruleForm.employeeList" v-if="index<4">
								<img class="e-hd" v-if="item.Type===3 && item.HeadUrl" :src="item.HeadUrl"></img>
								<span class="e-no-hd" v-if="item.Type===3 && !item.HeadUrl">员</span>
								<i class="el-icon-s-shop e-shop" v-if="item.Type!==3"
									style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
								{{item.Name}}
							</div>
							<div v-if="ruleForm.employeeList.length" class="show-more-box"
								@click="employeeVisible=true">
								<i class="el-icon-d-arrow-left show-more"></i>
							</div>
							<div>
								<el-link type="primary" style="line-height: 18px;" @click="employeeVisible=true">
									<span v-if="!ruleForm.employeeList.length">选择员工</span>
									<span v-else>修改可使用员工</span>
								</el-link>
							</div>

						</div>

					</el-form-item>
				</el-form>
			</el-card> -->

			<el-card style="margin-top: 15px;">
				<div slot="header" class="clearfix">
					<span>欢迎语配置</span>
				</div>
				<div class="welcome-edit-box">
					<el-form label-width="100px" class="edit-box-ruleForm">
						<el-form-item label="欢迎语" prop="employeeList">
							<div class="edit-wraper">
								<div class="title">
									<div style="margin-right: 20px;">
										<el-link type="primary" @click="insertWxNickName">插入客户微信昵称</el-link>
									</div>
									<!-- <div>
										<el-link type="primary" @click="insertEmployeeName">插入员工姓名</el-link>
									</div> -->
								</div>
								<div class="edit-box" ref="editBox" @blur="editBlur" contenteditable="plaintext-only"
									@input="checkLength"></div>
								<div class="num">{{length}}/1000</div>
								<div class="link-wraper">
					
									<div class="file-list" v-if="fileList.length">
										<div class="file-item" v-for="(item, index) in fileList" :key="index">
											<div class="type">
												<span v-if="item.Type == 1">【图片】</span>
												<span v-if="item.Type == 2">【链接】</span>
												<span v-if="item.Type == 3">【小程序】</span>
											</div>
											<div class="txt">：{{item.LinkName}}</div>
											<div class="control">
												<div class="edit" v-if="item.Type != 1" @click="handleEditFile(item, index)">
													<i class="el-icon-edit"></i>
												</div>
												<el-upload :action="imgApi" v-else :before-upload="beforeUpload"
													:on-success="(res, file)=>{handleUploadSuccess(res, file, index)}"
													accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
													<div class="edit">
														<i class="el-icon-edit"></i>
													</div>
												</el-upload>
												<div class="close" @click="handleDeleteFile(index)">
													<i class="el-icon-close"></i>
												</div>
											</div>
										</div>
									</div>
					
									<el-popover placement="top" v-model="showPop" width="200" trigger="click" v-if="!fileList.length">
										<div class="control-wraper">
											<el-upload :action="imgApi" :before-upload="beforeUpload"
												:on-success="(res, file)=>{handleUploadSuccess(res, file, -1)}"
												accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
												<div class="item">
													<img src="@/assets/img/icon-tupian.png">
													<div>图片</div>
												</div>
											</el-upload>
											<div class="item" @click="handleAddLink">
												<img src="@/assets/img/icon-lianjie.png">
												<div>链接</div>
											</div>
											<div class="item" @click="handleAddMiniProgram">
												<img src="@/assets/img/icon-xiaochengxu.png">
												<div>小程序</div>
											</div>
										</div>
										<el-link type="primary" slot="reference">+添加图片/链接/小程序
										</el-link>
									</el-popover>
								</div>
							</div>
						</el-form-item>
						
						<div class="tips-box" style="margin-left: 100px;max-width: 690px;">
							<div class="tit">提示：</div>
							<div class="cont">
								<div>1.进群欢迎语中，每条欢迎语最多可发送1条文字消息和1个附件</div>
								<div>2.欢迎语文本内容不可为空；若添加1个附件，客户进群后，将收到2条消息</div>
							</div>
						</div>
						
					</el-form>
					<div class="example">
						<img src="http://cdn.dkycn.cn/images/melyshop/join-group-example.png" alt="">
					</div>
				</div>
				

				
			</el-card>

			<div class="footer">
				<el-button @click="handleCancel">取消</el-button>
				<el-button type="primary" :loading="saveLoading" @click="handleSave">保存</el-button>
			</div>

			
			
			<!-- 选择员工 -->
			<select-employee v-if="ready" :visible="employeeVisible" @close="employeeVisible=false" @change="selectChange" :selected="ruleForm.employeeList"></select-employee>
			
			<!-- 链接 -->
			<link-pop :visible="miniVisible" :defaultData="editData" @close="miniVisible=false" @change="miniChange"></link-pop>
			
			<!-- 小程序 -->
			<miniprogram-card :visible="cardVisible" :defaultData="editData" @close="cardVisible=false" @change="cardChange"></miniprogram-card>

		</div>
	</no-auth>
</template>

<script>
	import {
		qyWeixinJoinGroupWelcomeedit,
		qyWeixinJoinGroupWelcomeinfo
	} from '@/api/sv1.0.0.js';

	import noAuth from './noAuth.vue';
	import selectEmployee from '@/components/selectEmployee/selectEmployee';
	
	import linkPop from '../../components/miniprogramLink.vue';
	import miniprogramCard from '../../components/miniprogramCard.vue';

	import config from '@/config/index'

	export default {
		components: {
			noAuth,
			selectEmployee,
			linkPop,
			miniprogramCard
		},
		data() {

			var checkList = (rule, value, callback) => {
				if (!value.length) {
					return callback(new Error('请选择可使用员工'));
				} else {
					callback();
				}
			};

			return {
				imgApi: config.UPLOAD_IMG,

				ruleForm: {
					employeeList: []
				},
				rules: {
					employeeList: [{
						required: true,
						trigger: 'change',
						validator: checkList
					}]
				},
				employeeVisible: false,
				length: 0,
				fileList: [],
				showPop: false,
				
				miniVisible: false,
				saveLoading: false,
				cardVisible: false,
				ready: false,
				
				editData: {}
			}
		},
		created() {},
		beforeMount() {},
		mounted() {
			this.id = this.$route.query.id || '';
			if (this.id){
				this.getData();
			}else{
				this.ready = true;
			}
			
		},
		methods: {
			filterlabel(e){
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				return newe
    	},
			async getData() {
				try {
					const res = await qyWeixinJoinGroupWelcomeinfo({
						Id: this.id
					})
					
					this.ruleForm.employeeList = res.Result.QyWeixinJoinGroupWelcome.CanUseEmployeeList;
					this.fileList = res.Result.QyWeixinJoinGroupWelcome.AttachmentInfoList;
					
					var html = res.Result.QyWeixinJoinGroupWelcome.Welcome;
					html = html.replace(/#员工姓名#/g, ' <span style="color: #409EFF;">#员工姓名#</span> ').replace(/#客户微信昵称#/g, ' <span style="color: #409EFF;">#客户微信昵称#</span> ')
					document.querySelector('.edit-box').innerHTML = html;
					
					this.checkLength();
					
					this.ready = true;
				} catch (e) {}
			},
			selectChange(list) {
				this.ruleForm.employeeList = list;
			},
			insertWxNickName() {
				var html = document.querySelector('.edit-box').innerHTML;
				document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#客户微信昵称#</span> ';
			},
			insertEmployeeName() {
				var html = document.querySelector('.edit-box').innerHTML;
				document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#员工姓名#</span> ';
			},
			checkLength() {
				var html = document.querySelector('.edit-box').innerText;
				html = html.replace('#客户微信昵称#', '').replace('#员工姓名#', '');
				this.length = html.length;
			},
			editBlur() {
				var html = this.filterlabel(document.querySelector('.edit-box').innerHTML);
				if (html.length > 1000) {
					document.querySelector('.edit-box').innerHTML = html.substr(0, 1000);
				}
			},
			
			
			handleEditFile(record, index){
				this.editIndex = index;
				if (record.Type == 2){
					this.miniVisible = true;
					this.editData = record;
				}else if (record.Type == 3){
					this.cardVisible = true;
					this.editData = record;
				}
			},
			handleAddLink(){
				this.editIndex = -1;
				this.miniVisible = true;
				this.editData = {};
			},
			handleAddMiniProgram(){
				this.editIndex = -1;
				this.cardVisible = true;
				this.editData = {};
			},
			

			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isLt2M) {
					this.$message.error('图片大小请控制在2M以内')
					return false
				}
				return true;
			},
			handleUploadSuccess(res, file, index) {
				var obj = {
					Type: 1, //1 图片类型
					LinkName: file.name,
					ImgUrl: res[0]
				}
				
				if (index == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(index, 1, obj)
				}
				
				this.showPop = false;
			},
			handleDeleteFile(index) {
				this.fileList.splice(index, 1)
			},
			miniChange(record) {
				
				var obj = {
					Type: 2, //2 链接
					LinkName: record.name,
					ImgUrl: record.imgUrl,
					LinkUrl: record.url,
					Description: record.desc
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				
			},
			cardChange(record) {
				
				var obj = {
					Type: 3, //3 小程序
					LinkName: record.name,
					LinkUrl: record.url,
					Description: record.desc,
					ImgUrl: record.imgUrl,
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				
			},
			
			handleCancel() {
				this.$router.replace({
					name: 'joinGroupMessage'
				})
			},
			handleSave() {
				// if (!this.ruleForm.employeeList.length) {
				// 	this.$message.error('请选择可使用员工');
				// 	return;
				// }

				var html = document.querySelector('.edit-box').innerHTML;
				html = html.replace(/<span style="color: #409EFF;">/g, '').replace(/<\/span>/g, '')
				if (!html) {
					this.$message.error('欢迎语文本内容不可为空');
					return;
				}
				
				this.save(html);
			},
			async save(html) {
				let params = {
					QyWeixinJoinGroupWelcome: {
						Id: this.id || 0,
						Welcome: html,
						// CanUseEmployeeList: this.ruleForm.employeeList.map(item => {
						// 	return {
						// 		Id: item.Id,
						// 		Type: item.Type
						// 	}
						// }),
						AttachmentInfoList: this.fileList
					}
				}
				
				this.saveLoading = true;
				try{
					const res = await qyWeixinJoinGroupWelcomeedit(params);
					
					this.$message.success('编辑成功');
					this.handleCancel()
				}catch(e){
					//TODO handle the exception
				}finally{
					this.saveLoading = false
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.friend-welcome-message {

		padding-bottom: 100px;
		
		.welcome-edit-box{
			display: flex;
			max-width: 1300px;
			
			.edit-box-ruleForm{
				flex: 1 1 auto;
				margin-right: 50px;
				overflow: hidden;
			}
			
			.example{
				flex: 0 0 auto;
				
				img{
					display: block;
					width: 350px;
				}
			}
		}

		.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background-color: #F6F6F6;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					background-color: #409eff;
					margin-right: 5px;
				}

				.e-delete {
					margin-left: 5px;
					font-size: 14px;
					cursor: pointer;
				}


			}

			.show-more-box {
				transform: rotate(-90deg);
				margin-top: 2px;
				cursor: pointer;
				margin-right: 4px;
			}

			.show-more {
				font-size: 16px;
				color: #999;

			}
		}

		.edit-wraper {
			border: 1px solid #ddd;
			background-color: #FBFDFF;
			line-height: 18px;
			border-radius: 4px;
			
			position: relative;

			.title {
				padding: 15px 15px;
				display: flex;
				border-bottom: 1px dashed #bbb;
			}

			.num {
				position: absolute;
				right: 10px;
				top: 254px;
				color: #999;
			}

			.link-wraper {
				padding: 15px;
				border-top: 1px solid #ddd;


			}

			.file-list {
				overflow: hidden;
				.file-item {
					display: flex;
					align-items: center;
					overflow: hidden;
					margin-bottom: 10px;
					// background: #cccbcb;
					// padding: 5px 10px;
					color: #666;
					font-size: 12px;

					>img {
						flex: 0 0 auto;
						width: 20px;
						margin-right: 5px;
					}
					
					.type{
						flex: 0 0 auto;
						width: 60px;
					}

					.txt {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// max-width: 80%;
					}
					.control{
						flex: 0 0 auto;
						margin-left: 10px;
						display: flex;
						
					}
					.edit {
						cursor: pointer;
						font-size: 16px;
					
						display: flex;
						align-items: center;
					}
					.close {
						margin-left: 5px;
						cursor: pointer;
						font-size: 16px;

						display: flex;
						align-items: center;
					}
				}
			}
		}

		.edit-box {
			height: 230px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
		}

	}

	.footer {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 220px;
		text-align: center;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-top: 1px solid #ddd;
	}

	@media screen and (max-width: 1280px) {
		.footer {
			left: 140px;
		}
	}
</style>
<style lang="less">
	.control-wraper {
		display: flex;

		.item {

			width: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>
